
import { defineComponent } from 'vue'
import ByocDetailsPage from '@/components/pages/chats/settings/byoc/byocDetails/ByocDetailsPage.vue'
import ByocDetailsBandwidthGeneral from '@/components/pages/chats/settings/byoc/byocDetails/bandwidth/ByocDetailsBandwidthGeneral.vue'
import ByocDetailsLinkedNumbers from '@/components/pages/chats/settings/byoc/byocDetails/ByocDetailsLinkedNumbers.vue'
import { providerBandwidth } from '@/definitions/chats/settings/byoc/data'

export default defineComponent({
  components: {
    ByocDetailsPage,
    ByocDetailsBandwidthGeneral,
    ByocDetailsLinkedNumbers,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Bring your own CPaaS', url: { name: 'base.chats.settings.byoc' } },
      { label: 'Bandwidth Production' },
    ]

    return {
      breadcrumbs,
      provider: providerBandwidth,
    }
  },
})
